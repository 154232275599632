<template>
  <div>
    <!-- -->
  </div>
</template>

<script>
export default {
  name: 'HcYmhfIndex',

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>
